@font-face {
  font-family: 'Dune';
  src:
          url('fonts/Dune.ttf?c7hm7f') format('truetype'),
          url('fonts/Dune.woff?c7hm7f') format('woff'),
          url('fonts/Dune.svg?c7hm7f#Dune') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="dune-"], [class*=" dune-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Dune' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dune-addiction:before {
  content: "\e925";
}
.dune-sterile_material_distribution1:before {
  content: "\e936";
}
.dune-alcohol:before {
  content: "\e909";
}
.dune-cure:before {
  content: "\e90c";
}
.dune-drugs:before {
  content: "\e90d";
}
.dune-games:before {
  content: "\e90e";
}
.dune-handicap:before {
  content: "\e90f";
}
.dune-postcure:before {
  content: "\e910";
}
.dune-prevention:before {
  content: "\e911";
}
.dune-psychosocial_support:before {
  content: "\e932";
}
.dune-substitution:before {
  content: "\e933";
}
.dune-support_groups:before {
  content: "\e934";
}
.dune-tobacco:before {
  content: "\e935";
}
.dune-home:before {
  content: "\e901";
}
.dune-accommodation:before {
  content: "\e900";
}
.dune-baggage_room:before {
  content: "\e902";
}
.dune-condom_distribution:before {
  content: "\e903";
}
.dune-contraception:before {
  content: "\e904";
}
.dune-cpas:before {
  content: "\e905";
}
.dune-cultural_activities:before {
  content: "\e906";
}
.dune-detainees_help:before {
  content: "\e907";
}
.dune-family:before {
  content: "\e908";
}
.dune-general_doctors:before {
  content: "\e90a";
}
.dune-health:before {
  content: "\e90b";
}
.dune-hopital:before {
  content: "\e912";
}
.dune-hygiene:before {
  content: "\e913";
}
.dune-hygienic_protection:before {
  content: "\e914";
}
.dune-ist_screening:before {
  content: "\e918";
}
.dune-justice:before {
  content: "\e919";
}
.dune-legal_advice:before {
  content: "\e91a";
}
.dune-lesson_and_training:before {
  content: "\e91b";
}
.dune-lockers:before {
  content: "\e91c";
}
.dune-meals:before {
  content: "\e91d";
}
.dune-mediation:before {
  content: "\e91e";
}
.dune-men_and_children:before {
  content: "\e91f";
}
.dune-men:before {
  content: "\e920";
}
.dune-mental_health_monitoring:before {
  content: "\e921";
}
.dune-mix:before {
  content: "\e922";
}
.dune-mobile_accompaniment:before {
  content: "\e923";
}
.dune-nurse_health:before {
  content: "\e924";
}
.dune-rdr:before {
  content: "\e937";
}
.dune-sex:before {
  content: "\e926";
}
.dune-sexual_violence_ressources:before {
  content: "\e927";
}
.dune-shower:before {
  content: "\e928";
}
.dune-social_help:before {
  content: "\e929";
}
.dune-social_permanence:before {
  content: "\e92a";
}
.dune-specialized_care:before {
  content: "\e92b";
}
.dune-sterile_material_distribution:before {
  content: "\e92c";
}
.dune-street_work:before {
  content: "\e92d";
}
.dune-ud_drug_service:before {
  content: "\e92e";
}
.dune-washing_machine:before {
  content: "\e92f";
}
.dune-women_and_children:before {
  content: "\e930";
}
.dune-women:before {
  content: "\e931";
}
