@import url('https://fonts.googleapis.com/css?family=Quicksand');

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Quicksand !important;
  font-weight: 300;
  height: 100%;
}

.navbar{
  border-bottom: 1px solid #dddddd;

  -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.27);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.27);
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.27);

}

.form-error {
  color: #e74c3c;
  font-weight: bold;
}