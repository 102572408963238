@import url(https://fonts.googleapis.com/css?family=Quicksand);
html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Quicksand !important;
  font-weight: 300;
  height: 100%;
}

.navbar{
  border-bottom: 1px solid #dddddd;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.27);

}

.form-error {
  color: #e74c3c;
  font-weight: bold;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.icon-medium {
  font-size: 32px;
}

.service-card {
  overflow: hidden;
}

.space-medium span {
  margin-right: 0.5em;
}

.icon-big {
  font-size: 3em;
}

.service-input label span{
  font-size: 36px;
}
.service-input .is-checkradio[type=checkbox] {
  display: none;
}

@font-face {
  font-family: 'Dune';
  src:
          url(/static/media/Dune.78a4374c.ttf) format('truetype'),
          url(/static/media/Dune.23ec0321.woff) format('woff'),
          url(/static/media/Dune.34c8b2b3.svg#Dune) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="dune-"], [class*=" dune-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Dune' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dune-addiction:before {
  content: "\e925";
}
.dune-sterile_material_distribution1:before {
  content: "\e936";
}
.dune-alcohol:before {
  content: "\e909";
}
.dune-cure:before {
  content: "\e90c";
}
.dune-drugs:before {
  content: "\e90d";
}
.dune-games:before {
  content: "\e90e";
}
.dune-handicap:before {
  content: "\e90f";
}
.dune-postcure:before {
  content: "\e910";
}
.dune-prevention:before {
  content: "\e911";
}
.dune-psychosocial_support:before {
  content: "\e932";
}
.dune-substitution:before {
  content: "\e933";
}
.dune-support_groups:before {
  content: "\e934";
}
.dune-tobacco:before {
  content: "\e935";
}
.dune-home:before {
  content: "\e901";
}
.dune-accommodation:before {
  content: "\e900";
}
.dune-baggage_room:before {
  content: "\e902";
}
.dune-condom_distribution:before {
  content: "\e903";
}
.dune-contraception:before {
  content: "\e904";
}
.dune-cpas:before {
  content: "\e905";
}
.dune-cultural_activities:before {
  content: "\e906";
}
.dune-detainees_help:before {
  content: "\e907";
}
.dune-family:before {
  content: "\e908";
}
.dune-general_doctors:before {
  content: "\e90a";
}
.dune-health:before {
  content: "\e90b";
}
.dune-hopital:before {
  content: "\e912";
}
.dune-hygiene:before {
  content: "\e913";
}
.dune-hygienic_protection:before {
  content: "\e914";
}
.dune-ist_screening:before {
  content: "\e918";
}
.dune-justice:before {
  content: "\e919";
}
.dune-legal_advice:before {
  content: "\e91a";
}
.dune-lesson_and_training:before {
  content: "\e91b";
}
.dune-lockers:before {
  content: "\e91c";
}
.dune-meals:before {
  content: "\e91d";
}
.dune-mediation:before {
  content: "\e91e";
}
.dune-men_and_children:before {
  content: "\e91f";
}
.dune-men:before {
  content: "\e920";
}
.dune-mental_health_monitoring:before {
  content: "\e921";
}
.dune-mix:before {
  content: "\e922";
}
.dune-mobile_accompaniment:before {
  content: "\e923";
}
.dune-nurse_health:before {
  content: "\e924";
}
.dune-rdr:before {
  content: "\e937";
}
.dune-sex:before {
  content: "\e926";
}
.dune-sexual_violence_ressources:before {
  content: "\e927";
}
.dune-shower:before {
  content: "\e928";
}
.dune-social_help:before {
  content: "\e929";
}
.dune-social_permanence:before {
  content: "\e92a";
}
.dune-specialized_care:before {
  content: "\e92b";
}
.dune-sterile_material_distribution:before {
  content: "\e92c";
}
.dune-street_work:before {
  content: "\e92d";
}
.dune-ud_drug_service:before {
  content: "\e92e";
}
.dune-washing_machine:before {
  content: "\e92f";
}
.dune-women_and_children:before {
  content: "\e930";
}
.dune-women:before {
  content: "\e931";
}

.background-card-custom{
    background-color: #31606B;
    height: 136px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.service-background{
    background-color: #31606B;
    margin-left: 5px;
    padding: 2px;
    min-height: 32px;
}

.service-no-background{
    margin-left: 5px;
    padding: 2px;
    min-height: 32px;
}

.is-minimum-height-32{
    min-height: 32px;
}

.relative-position{
    position: relative;
}

.edit-container{
    position: absolute;
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    background-color: white;
    right: 0.5rem;
    bottom: -1rem;
    color: #31606B;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.edit-container .icon {
    height: 2rem;
    width: 2rem;
}

.card-attention {
    position: absolute;
    top: 10px;
    right: 10px;
}
.subtitle-left-icon{
    margin-right: 2rem;
}

.edition-left-icon{
    margin-right: 0.5rem;
}

.notification.is-custom-color{
    background-color: #31606B;
    color: #fff;
}

.map-for-edition{
    width: 600px;
    heigth: 300px;
}

.translation-description{
    min-height: 8rem;
}

.translation-shortdescription{
    min-height: 2rem;
}

.translation-openinghours{
    min-height: 2rem;
}

.translation-original-content{
    border-right: solid 1px #dbdbdb;
    padding-right: 1rem;
}

.header-image-tile img{
    object-fit: contain;

}

.header-image-tile-big img{
    object-fit: contain;
    max-width:1920px;
    max-height:672px;
    width: auto;
    height: auto;

}

.notice.is-custom-color{
    background-color: rgb(228, 196, 91);
    color: #fff;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
}

.checkbox input{
    margin-right: 5px;
}

.lateral-menu .menu-list li.is-active a {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #3273dc;
    color: #3273dc;
}

.hidden{
    display: none;
}

.bottom-actions {
    margin-top: 1em;
}

.bottom-actions .buttons .button {
    width: 100%;
}


.css-1n7ec1i-line pre{
    padding: 0.5rem 0.75rem;
}

.css-1n7ec1i-line:last-child{
    margin-bottom: 2rem;
}

table {
    margin-bottom: 3rem;
}

.section {
    padding: 20px 25px;
}

.background-image{
    background: url(/static/media/background.e0efe160.jpg) no-repeat 50% 50% fixed;
    background-size: cover;
    background-position: center center;
    width: 100vw;
    height: 100vh;
}

.auth-form{
    background: #fefefe;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    padding: 20px;
    border-radius: 4px;
}



.auth-form h1 {
    text-align: center;
}

.button.is-expanded {
    width: 100%;
}
