.subtitle-left-icon{
    margin-right: 2rem;
}

.edition-left-icon{
    margin-right: 0.5rem;
}

.notification.is-custom-color{
    background-color: #31606B;
    color: #fff;
}

.map-for-edition{
    width: 600px;
    heigth: 300px;
}

.translation-description{
    min-height: 8rem;
}

.translation-shortdescription{
    min-height: 2rem;
}

.translation-openinghours{
    min-height: 2rem;
}

.translation-original-content{
    border-right: solid 1px #dbdbdb;
    padding-right: 1rem;
}

.header-image-tile img{
    object-fit: contain;

}

.header-image-tile-big img{
    object-fit: contain;
    max-width:1920px;
    max-height:672px;
    width: auto;
    height: auto;

}

.notice.is-custom-color{
    background-color: rgb(228, 196, 91);
    color: #fff;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
}

.checkbox input{
    margin-right: 5px;
}