
.lateral-menu .menu-list li.is-active a {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #3273dc;
    color: #3273dc;
}

.hidden{
    display: none;
}

.bottom-actions {
    margin-top: 1em;
}

.bottom-actions .buttons .button {
    width: 100%;
}
