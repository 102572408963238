
.css-1n7ec1i-line pre{
    padding: 0.5rem 0.75rem;
}

.css-1n7ec1i-line:last-child{
    margin-bottom: 2rem;
}

table {
    margin-bottom: 3rem;
}

.section {
    padding: 20px 25px;
}
