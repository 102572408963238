.background-image{
    background: url('background.jpg') no-repeat 50% 50% fixed;
    background-size: cover;
    background-position: center center;
    width: 100vw;
    height: 100vh;
}

.auth-form{
    background: #fefefe;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 400px;
    padding: 20px;
    border-radius: 4px;
}



.auth-form h1 {
    text-align: center;
}

.button.is-expanded {
    width: 100%;
}