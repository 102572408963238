.background-card-custom{
    background-color: #31606B;
    height: 136px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.service-background{
    background-color: #31606B;
    margin-left: 5px;
    padding: 2px;
    min-height: 32px;
}

.service-no-background{
    margin-left: 5px;
    padding: 2px;
    min-height: 32px;
}

.is-minimum-height-32{
    min-height: 32px;
}

.relative-position{
    position: relative;
}

.edit-container{
    position: absolute;
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    background-color: white;
    right: 0.5rem;
    bottom: -1rem;
    color: #31606B;
    -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.edit-container .icon {
    height: 2rem;
    width: 2rem;
}

.card-attention {
    position: absolute;
    top: 10px;
    right: 10px;
}